<template>
    <div>
        <v-row justify="center">
            <!-- <v-col v-if="portfolios.length == 0">
                <v-progress-linear indeterminate class="indigo"></v-progress-linear>
            </v-col> -->
             <!-- v-if="portfolios.length > 0" -->
            <v-col cols="12">
                <v-tabs v-model="tab" background-color="grey lighten-2" show-arrows next-icon="mdi-menu-right" prev-icon="mdi-menu-left">
                    <v-tab v-for="(item,index) in symbols" :key="index">
                        {{ item }}
                    </v-tab>
                </v-tabs>
                <v-row dense v-if="loading"> 
                    <v-col cols="12">
                        <v-skeleton-loader dense type="table-row-divider@20" class="mx-auto"></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-tabs-items v-model="tab">               
                    <v-tab-item v-for="(item,index) in portfolios" :key="index" >
                            <v-data-table dense :headers="PortfolioHeader" hide-default-footer v-if="!loading">
                                <template  v-slot:body>
                                    <tbody>
                                        <tr v-for="(signal,index) in item" :key="index">
                                            <td class="text-center">{{ index + 1 }}</td>
                                            <td class="text-center">{{ common.dateFormat(signal.timestamp) }}</td>
                                            <td class="text-center" :class="signal.side == 'short' ? 'red--text' : 'green--text' ">{{ signal.side }}</td>
                                            <td class="text-right">{{ common.formatNum(signal.entryPrice.toFixed(4)) }}</td>
                                            <td class="text-right">{{ common.formatNum(signal.size.toFixed(5)) }}</td>
                                            <td class="text-right">{{ signal.status == 'open' ? '--' : common.formatNum(signal.exitPrice.toFixed(4)) }}</td>
                                            <td class="text-right" :class="signal.gl < 0 ? 'red--text' : ''">{{ signal.status == 'open' ? '--' :'$' + common.formatNum(signal.gl.toFixed(2)) }}</td>
                                            <td class="text-right" :class="signal.result < 0 ? 'red--text' : ''">{{ signal.status == 'open' ? '--' : common.formatNum(signal.result.toFixed(2)) + '%' }}</td>
                                            <td class="text-right">{{ signal.status }}</td>
                                            <td class="text-right">{{ signal.status == 'open' ? '--' : '$' + common.formatNum(signal.value.toFixed(2)) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                tab:null,
                symbols:[],
                portfolios:[],
                loading:false,
                PortfolioHeader: [
                    { sortable: false, text: "No", align:'center'},
                    { sortable: false, text: "Date", align:'center' },
                    { sortable: false, text: "Signal", align:'center' },
                    { sortable: false, text: "Entry Price", align:'right' },
                    { sortable: false, text: "Size", align:'right' },
                    { sortable: false, text: "Exit Price", align:'right' },
                    { sortable: false, text: "G/L", align:'right' },
                    { sortable: false, text: "Result", align:'right' },
                    { sortable: false, text: "Status", align:'right' },
                    { sortable: false, text: "Portfolio Value", align:'right' },
                ],
            }
        },
        computed: {
            ...mapGetters(['user','signalSymbols']),
        },
        watch: {
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({
                    name: 'login'
                })
            };
            this.getAllPortfolio();
        },
        created() {

        },
        methods: {
            //查询所有的portfolio
            getAllPortfolio(){
                this.loading = true;
                let api = config.baseUrl + "/admin/private/getPortfolios";
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.loading = false;
                        this.portfolios = data.result.portfolios;
                        for(var i = 0 ; i<data.result.symbols.length;i++){
                            data.result.symbols[i] = data.result.symbols[i].replace(/USD/,"");
                        }
                        this.loading = false;
                        this.symbols = data.result.symbols;
                        this.$store.dispatch("lastObjSymbolsHandler",data.result.lastPrice);
                    }else{
                        this.$store.dispatch("snackbarMessageHandler",data.message);
                        this.loading = false;
                    }
                })
            },
            // 首字母大写
            toFirstLetterUpperCase(message) {
                if (message) {
                    return message.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
                } else {
                    return null;
                }
            },
        }
    }
</script>

<style>

</style>