<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row dense>
                <v-col cols="12">
                    <!-- 绿色框 -->
                    <v-alert :dismissible="false" class="indigo white--text" dark border="left" icon="fas fa-table">
                        <v-row no-gutters class="align-center mt-n1 mb-n1">
                            <v-col cols="10" class="pa-0">
                                <span>Portfolio</span>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
                <!-- 结果 -->
                <v-col cols="12">
                    <signals-portfolio></signals-portfolio>
                </v-col>
                <!-- <v-col cols="12" class="px-0">
                    <v-tabs v-model="type" color="indigo">
                        <v-tab href="#history">history</v-tab>
                        <v-tab href="#recent">recent</v-tab>
                        <v-tab href="#signals">signals portfolio</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="type">
                        <v-tab-item value="history">
                        <PortfolioResult></PortfolioResult>
                        </v-tab-item>
                        <v-tab-item value="recent">
                            <PortfolioLast></PortfolioLast>
                        </v-tab-item>
                        <v-tab-item value="signals">
                            <signals-portfolio></signals-portfolio>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col> -->
            </v-row>
        </v-container>
    </div>

</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import {  mapGetters } from "vuex";
    import SignalsPortfolio from './SignalsPortfolio';
    // import PortfolioLast from './PortfolioLast';
    // import PortfolioResult from './PortfolioResult';
    export default {
        data() {
            return {
                type:"result",
                all:true,
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        components:{
            SignalsPortfolio
            // PortfolioLast,
            // PortfolioResult
        },
        watch: {
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({
                    name: 'login'
                })
            };
        },
        created() {

        },
        methods: {
            
        }
    }
</script>

<style>

</style>